<template>
  <div class="body-cont">
		<div class="div-container app-header">
			<div class="div-row">
				<div class="div-col12">
          <NavTopbar :user="user"/>
				</div>
			</div>
		</div>
    <div class="app-body-container livebody-content">
        <div class="app-body-row">
            <div class="app-body-col">
                  <div class="livestream-body">
                      <div v-if="isSteamer">
                           <video  ref="remoteAudio" id="remoteAudio" muted="true" class="remote " style="display: none;"></video>
                           <video  autoplay playsinline id="myVideo" ref="localAudio" muted="true" class="local streem"></video>

                      </div>
                      <div v-else>
                        <video    id="myVideo"  ref="localAudio" muted="true" class="local viewer" style="width:170px;z-index: 1; display:none; position: absolute;"></video>
                        <div   class="local viewer" style="width:170px;z-index: 1; position: absolute;">
                          <div style="float:left; width: 30%;">
                          <v-avatar  >
                            <v-img :src="streamerImg"></v-img>
                          </v-avatar>
                        </div>
                        <div style="float:left;width: 50%;">
                          <p v-text="stremerName" style="color:aliceblue;text-align:center;text-transform:capitalize;padding-top: 12px;padding-left: 12px; margin: auto;"></p>
                          </div>
                        </div>

                        <video  autoplay="true"    muted="muted"  poster="/images/processing.jpg"  ref="remoteAudio" id="remoteAudio" class="remoteviewer"></video>
                      </div>
                  </div>

                <div class="livestram-boticon">
                  <div class="liveacc-imgicon">
                    <table class="liveacc-botbar-table">
                      <tbody>
                        <tr>
                          <td>
                            <a href="javascript:void(0)" v-if="isSteamer"  class="live-bot-icon" @click="toggleCamera()" ><img class="strambt-icon" ref="cameraOn" v-bind:src="cameraon"></a>
                          </td>
                          <td>
                            <a href="javascript:void(0)" v-if="isSteamer" class="live-bot-icon" @click="toggleAudio()"><img class="strambt-icon" ref="micOn" :src="micOn"></a>
                          </td>
                          <td>
                            <a href="javascript:void(0)" v-if="isSteamer" class="live-bot-icon"><img class="strambt-icon" :src="recordingOn"></a>
                          </td>

                          <td>
                            <button class="live-bot-livbtn" @click="toggleVideo()"><img class="strambt-icon" :src="videoPlay"></button>
                          </td>
                          <td>
                            <a href="javascript:void(0)" class="live-bot-icon" @click="toogleValuelevel()"><img class="strambt-icon" ref="soundOn" :src="soundOn"></a>
                          </td>
                          <td>
                            <a href="javascript:void(0)" v-if="isSteamer" class="live-bot-icon" @click="toggleSwitchcamera()"><img class="strambt-icon" ref="backCameraOn" :src="backCameraOn"></a>
                          </td>

                          <td>
                            <a href="javascript:void(0)"  @click="togglePictureInPicture()" class="live-bot-icon"><img class="strambt-icon" ref="picInpicOn" :src="picInpicOn"></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="viewers-sec">
                    <div class="viewrs-numsec"  @click="showListview()">
                      <table class="vierslist-tavle" v-show="isViewTrue">
                        <tbody><tr>
                          <td><p class="viewrs-titletxt"><span><i class="fa fa-angle-down"></i></span> Viewers</p></td>
                          <td><p class="total-viersnum" v-text="viewers.length"></p></td>
                        </tr>
                      </tbody></table>
                    </div>
                    <div id="myDIV" v-show="showlist" v-for="user in viewers" :key="user.id" class="viewrs-list">
                      <p class="viersname">
                                            <v-img :src="user.viewer.avatar_full_path" style="display: none;"></v-img>
                                            {{ user.viewer.name }}</p>

                    </div>
                  </div>
                </div>



            </div>
          </div>

    </div>

  <div id="log"></div>
  <div id="videoSettingsText"></div>
  <div id="audioSettingsText"></div>
</div>
</template>


<script>
//import broadcast from '@/components/Broadcasts.vue';
//import watcher from '@/components/Watch.vue';
import api from "@/api.js";
import baseimg from "@/baseimg.js";
import { uuid } from 'vue-uuid'; // uuid object is also exported to things
import NavTopbar from "@/components/NavTopbar";

import { mapState } from "vuex";
//import { version } from 'vue/types/umd';
    export default({
    props: ["user", "newNotification"],
    components: {
      'NavTopbar':NavTopbar
        //  broadcast:broadcast,
        //  watcher:watcher
    },
    data() {
      return{
      isSteamer:false,
      streamer:false,
      localStream:null,
      peerStream:null,
      room_id:null,
      getUserMedia:navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia,
      local_stream:null,
      screenStream:null,
      peer : null,
      globalpeer:null,
      currentPeer : null,
      screenSharing: false,
      viewers:[],
      camera_switch : true,
      camera_onof:true,
      cameraon:baseimg.activeCamera,
      micOn:baseimg.activeMic,
      recordingOn:baseimg.activeRecording,
      videoPlay:baseimg.playVideo,
      soundOn:baseimg.inactiveSound,
      backCameraOn:baseimg.frontCamera,
      picInpicOn:baseimg.activepicInpic,
      cameraSwitch:false,
      isCamera:true,
      ismic:true,
      isrecording:false,
      isplay:true,
      isvalueme:false,
      isbackfront:true,
      ispinp:false,
      active:false,
      isViewTrue:false,
      showlist:false,
      uniqueRoom:uuid.v4(),
      capture:null,
      environment:'user',
      CurrentCamera:'',
      streamerImg:baseimg.defaultAvatar,
      stremerName:null,
      front:true,
      message:'',
      videoElement : document.getElementById("myVideo"),
      videoSettingsText:document.getElementById("videoSettingsText"),
      audioSettingsText:document.getElementById("audioSettingsText"),
      logElement:document.getElementById("log"),
      videoConstraints : true,
      audioConstraints : true,
      audioTrack : null,
      videoTrack : null,
      videoDefaultConstraintString:'',
      audioDefaultConstraintString:'',
      version_code:null
    }
    },
    watch: {
      user: {
          handler(value) {
              if (!value.id) {
                  return;
              }
              const userauth = localStorage.getItem("userId");
              if(userauth){
                if(userauth ==this.$route.params["id"]){
                   this.isStreamer = true;
                }else{
                   this.isStreamer = false;
                }
              }else{
              }
            },
          deep: true,
          immediate: true
      },

    },
    beforeMount() {
               const userauth =Number(localStorage.getItem("userId"));
               localStorage.setItem("isfront", 0);

              if(userauth){
                if(userauth==Number(this.$route.params["id"])){
                   this.isStreamer = true;
                }else{
                   this.isStreamer = false;
                }
              }else{
              }
    },
    computed:{
    items() {
            const items = [
                { name: "userMenu", text: this.user.name, icon: "fa-solid fa-user" },
                {
                    name: "notifications",
                    text: "Notifications",
                    icon: "fa-solid fa-bell"
                },
                { name: "contacts", text: "Contacts", icon: "fa-regular fa-address-book" },
               /* {
                    name: "people",
                    text: "People",
                    icon: "fa-solid fa-users"
                },*/

               // { name: "settings", text: "Settings", icon: "fa-solid fa-gear" },
                { name: "logout", text: "Logout", icon: "fa-sharp fa-solid fa-right-from-bracket" }
            ];
            return items;
        },

    },
    mounted() {
              const userauth = localStorage.getItem("userId");
              if(userauth){
                if(userauth ==Number(this.$route.params["id"])){
                   this.isSteamer = true;
                     //this.captureVideo('environment');
                     document.onreadystatechange = () => {
                      if (document.readyState == "complete") {
                        this.createRoom();
                      }
                    }
                }else{
                   this.isSteamer = false;
                   this.joinRoom();

                }
                setInterval(() => {this.getAllViewers();}, 3000);
                if(this.isStreamer==false){
                  setInterval(() => {this.getStreamVersions();}, 3000);
                }
               // setInterval(() => {
               //   this.checktack();
               // }, 5000);


              }else{

              }


    },
    created() {
    document.addEventListener('beforeunload', this.unsavedChanges);
    window.addEventListener('beforeunload', this.unsavedChanges);
  },
    methods:{
      checktack(){
          if(document.getElementById("remoteAudio") !="undefined"){
             var videoEle=document.getElementById("remoteAudio");
          }else{
           var videoEle= this.$refs.remoteAudio;
          }
          //const tracks = videoEle.getVideoTracks;
          console.log(videoEle);
          //console.log(tracks);
          const videoTracks=this.peerStream.getVideoTracks();
          for (let i = 0; i !== videoTracks.length; ++i) {
                    console.log(videoTracks[i]);
                    //.stop();
                    }

      },
      async unsavedChanges(){
        let loggeduser=localStorage.getItem("userId");
        let activeStreamer = Number(this.$route.params["id"]);
        if(this.isStreamer){
         await api.user.removeViewer(loggeduser,null);
         let res =  await api.user.update(activeStreamer,{'roomid':''});
        }else{
          await api.user.removeViewer(activeStreamer,loggeduser);
        }
        return true;
      },
      getCurrentSettings() {
        if (this.videoTrack) {
          this.videoSettingsText.value = JSON.stringify(this.videoTrack.getSettings(), null, 2);
        }
        if (this.audioTrack) {
          this.audioSettingsText.value = JSON.stringify(this.audioTrack.getSettings(), null, 2);
        }
     },
      buildConstraints(){
        this.videoDefaultConstraintString ='{\n  "width": 360,\n  "height": 800,\n  "frameRate": 30\n}';
        this.audioDefaultConstraintString ='{\n  "sampleSize": 16,\n  "channelCount": 2,\n  "echoCancellation": false\n}';
      },
      log(msg) {
        let logElement=document.getElementById("log");
        logElement.innerHTML += `${msg}<br>`;
      },
      handleError(reason) {
        this.log(
          `Error <code>${reason.name}</code> in constraint <code>${reason.constraint}</code>: ${reason.message}`,
        );
        //alert(reason.message);
      },
      switchCameras(track, camera) {
        let res = api.user.update(Number(this.$route.params["id"]),{'stream_version':uuid.v4()});
          const constraints = track.getConstraints();
          constraints.facingMode = camera;
         // alert(constraints.facingMode + camera);
          track.applyConstraints(constraints);
          //console.log(constraints);
          const constraintsT={video:constraints,audio:true };
         // console.log(constraintsT);
          if (this.local_stream) {
            this.local_stream.getTracks().forEach(track => track.stop());
            const videoTracks = this.local_stream.getVideoTracks();
            const audioTracks = this.local_stream.getAudioTracks();
              for (let i = 0; i !== videoTracks.length; ++i) {
                    videoTracks[i].stop();
                    }
              for (let i = 0; i !== audioTracks.length; ++i) {
                audioTracks[i].stop();
              }
            }
          //console.log(this.currentPeer);
          this.NewWebrtc(constraintsT);

      },
      whichCamera(track) {
        console.log(track.getSettings().facingMode);
        return track.getSettings().facingMode;
      },
       async createRoom() {
              localStorage.setItem("isfront", 0);
              let room= this.uniqueRoom ;'Streemings'+Number(this.$route.params["id"]);
              let res =  await api.user.update(Number(this.$route.params["id"]),{'roomid':room,'stream_version':uuid.v4()});
              if(res.data){
                  let room_id = res.data.roomid;
                  let options='';
                  if(this.user.peerjsconfig){
                    options=this.user.peerjsconfig;
                  }
                  this.buildConstraints();
                    navigator.mediaDevices
                      .getUserMedia({
                        video: this.videoConstraints,
                        audio: this.audioConstraints,
                      })
                      .then((stream) => {
                       let  videoElement = document.getElementById("myVideo");
                       if(document.getElementById("myVideo") !="undefined"){
                       videoElement=document.getElementById("myVideo");
                       }else{
                        videoElement= this.$refs.localAudio
                       }
                        const audioTracks = stream.getAudioTracks();
                        const videoTracks = stream.getVideoTracks();
                        videoElement.srcObject = stream;
                        this.local_stream=stream;
                        this.readyToStreaming(room_id,options);
                        if (audioTracks.length > 0) {
                          this.audioTrack = audioTracks[0];
                        }
                        if (videoTracks.length > 0) {
                          this.videoTrack = videoTracks[0];
                        }
                      })
                      .then(() => {
                        return new Promise((resolve) => {
                          videoElement.onloadedmetadata = resolve;
                        });
                      })
                      .then(() => {
                        this.getCurrentSettings();
                      })
                      .catch(this.handleError);
                }


          },

          async devicelist(){
            const devices = await navigator.mediaDevices.enumerateDevices();
            let videoDevices = devices.filter(device => device.kind === 'videoinput');
            return videoDevices;
          },

          readyToStreaming(room_id,options){
                  this.globalpeer = new Peer(room_id,options);
                  this.globalpeer.on('open', (id) => {
                    try {

                    //  this.CurrentCamera.getVideoTracks()[0].applyConstraints({ video:{facingMode:{ideal: this.environment}}, audio: true });
                      //this.$refs.localAudio.srcObject = this.CurrentCamera;
                    //  this.setLocalStream(this.CurrentCamera);
                    } catch(err){
                      console.log(err);
                      alert(err);
                    };
                  }).on('call', (call) => {
                    console.log(call);
                    call.answer(this.local_stream);
                    call.on('stream', (stream) => {
                      this.setRemoteStream(stream);
                    });
                    this.currentPeer = call;
                  }).on('error', function(err){
                    if(err.type == 'browser-incompatible'){
                      this.message="The Client's Browser does not support some or all WebRTC features";
                    }else if(err.type =="disconnected"){
                      this.message="You've already disconnected this peer from the server and can no longer make any new connection on it";
                    }
                    else if(err.type =="invalid-id"){
                     this.message="The ID passed into the Peer constructor contains illegal characters";
                    }
                    else if(err.type =="invalid-key"){
                      this.message="The API ke passed into the Peer Constructor contains illegal characters or is not in the system";
                    }
                    else if(err.type =="network"){
                      this.message="Lost or cannot establish a connection to the signalling server";
                    }
                    else if(err.type =="peer-unavailable"){
                      this.message="The pee you're trying to connect to does not exist";
                    }
                    else if(err.type =="ssl-unavailable"){
                      this.message="Peerjs is being used securely, but the server does not support SSL";
                    }
                    else if(err.type =="server-error"){
                      this.message="Unable to reach the server.";
                    }
                    else if(err.type =="socket-error"){
                      this.message="An error from the underlying socket";
                    }
                    else if(err.type =="socket-closed"){
                      this.message="The underlying socket closed unexpectedly";
                    }
                    else if(err.type =="unavailable-id"){
                      this.message="The ID Passed into the Peer constructor is already taken";
                    }
                    else if(err.type =="webrtc"){
                      this.message="Native Webrtc errors";
                    }
                    //alert(this.message);
                  });
          },
          async joinRoom() {
                console.log("Joining Room")
                const res = await api.user.getRoomID(Number(this.$route.params["id"]));
                const version_res = await api.user.getStreamVersion(Number(this.$route.params["id"]));
                this.version_code=version_res.data;
                let room=res.data;
                let room_id = room;
                let options='';
                if(this.user.peerjsconfig){
                  options=this.user.peerjsconfig;
                }
                let peer = new Peer(options);
                peer.on('open', (id) => {
                   navigator.mediaDevices.getUserMedia({ video:{facingMode:{ideal: this.environment}}, audio: true }).then(stream => {
                        //this.setLocalStream(stream);
                        let call = peer.call(room_id, stream);
                        //call.answer(stream);
                        call.on('stream', (Remotestream) => {
                          console.log(Remotestream);
                        let  videoSElement = document.getElementById("remoteAudio");
                        if( typeof this.$refs.remoteAudio != "undefined") {
                           videoSElement = this.$refs.remoteAudio;
                        }else{
                          videoSElement = document.getElementById("remoteAudio");
                        }
                         this.peerStream = Remotestream;

                        videoSElement.srcObject = Remotestream;
                       /**  var playPromise = videoSElement.play();

                            if (playPromise !== undefined) {
                                 playPromise.then(_ => {
                                // Automatic playback started!
                                // Show playing UI.
                              })
                              .catch(error => {
                                console.log(error);
                                // Auto-play was prevented
                                // Show paused UI.
                              });
                            } **/

                        });
                       // call.on('disconnected', function() {
                        //  location.reload();
                       // });
                        call.on('close', () => {
                          location.reload();
                        })
                        this.currentPeer = call;
                    })

                    .catch(err => {
                        throw new Error(`Unable to fetch stream ${err}`);
                    });

                });
                peer.on('disconnected', function() {
                          location.reload();
                });
                peer.on('close',()=>{
                     console.log('close');
                    //this.checkCamerachange(room_id);
                });
                peer.on('error', function(err){
                    alert('Streaming is offline')
                  location.href = "/dashboard";

                  if(err.type == 'peer-unavailable'){
                    //alert('Streamer is Offline');

                  }
                  console.log(err.type);
                });
          },
          async checkCamerachange(room_id){
            const res = await api.user.getRoomID(Number(this.$route.params["id"]));
            let room=res.data;
            if(room!=room_id){
              this.joinRoom();
            }else{
              location.href = "/dashboard";
            }
          },
          setLocalStream(stream) {
            this.local_stream=null;
            this.local_stream=stream;
            let video = document.getElementById("myVideo");
            if( typeof this.$refs.localAudio != "undefined") {
                this.$refs.localAudio.srcObject=null;
                this.$refs.localAudio.srcObject = this.local_stream;
                this.$refs.localAudio.autoplay = true;
            } else {
                video.srcObject=null;
                video.srcObject=this.local_stream;
                video.autoplay=true;
            }
          },
          setRemoteStream(stream){
            let video = document.getElementById("remoteAudio");
            if( typeof this.$refs.remoteAudio != "undefined") {
                this.$refs.remoteAudio.srcObject=null;
                this.$refs.remoteAudio.srcObject = stream;
                this.$refs.remoteAudio.autoplay = true;
                this.peerStream = stream;
            } else {
                video.srcObject=null;
                video.srcObject=stream;
                video.autoplay=true;
                this.peerStream = stream;
            }
          },


          changeStreaming(environment){
            console.log(navigator);
                navigator.mediaDevices.getUserMedia({ video:{facingMode:{ideal: environment}}, audio: true }).then(stream => {
                        this.local_stream = stream;
                        this.setLocalStream(stream);
                },(err)=>{
                      console.log(err);
                      alert(JSON.stringify(err));
                }).catch(err=>{
                  console.log(err);
                });

          },
          notify(msg) {
              let notification = document.getElementById("notification")
              notification.innerHTML = msg
              notification.hidden = false
              setTimeout(() => {
                  notification.hidden = true;
              }, 3000)
          },


        async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = true;
            this.active=false;
            await api.notification.markAsRead();
          // this.getUser();
        },
        CloseNotification(){
        this.showNotifications= false;
        this.active=true;
        },
        toggleAddressBooksclose(){
        this.showAddressBook= false;
        this.active=true;
        },
        async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = true;
            this.active= false;
        },
        showListview(){
          this.showlist= !this.showlist;
        },
        async getStreamVersions(){
          const version_res = await api.user.getStreamVersion(Number(this.$route.params["id"]));
          console.log(this.version_code);
          if(this.version_code==version_res.data){

          }else{
           location.reload();
          }
        },
        async getAllViewers() {
          this.isViewTrue=true;
          const res = await api.user.getAllConnectedViewers(
              Number(this.$route.params["id"]),
              {
                  params: {
                      category: null,
                      connected:localStorage.getItem("userId"),
                  }
              }
          );
          let viewers = res.data;
          let Streamingprofile = viewers.streamer;
          this.streamerImg=Streamingprofile.place_avatar_full_path;
          this.stremerName=Streamingprofile.name;

          this.viewers = viewers.viewers.filter(
              viewer => viewer.viewer.status === "online"
          );
        },
        menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                //case "people":
                //    this.goToHome();
                //    break;
              /**   case "randomChat":
                    location.href = "/video-chat";
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                    */
                case "logout":
                    this.logout();
                    break;
            }
        },
        mouseOverFun(){
            if(this.active){
            this.active =false;
            }else{
              this.active=true;
            }
        },
        mouseOverout: function(){
              this.active =false;
        },
        goToHome() {
        location.href = "/";
        },
        goToDashboard() {
        location.href = "/dashboard";
        },
        logoSrc() {
            return "/images/logo/net-app-log-white.png";
        },
        logoVideoSrc() {
                return "/images/logo/video-camera-white.png";
        },
        goToNewStreamingPage(){
          location.href = "/new-streaming";
        },
        toggleVideo() {
          let video= null;
          if(this.isSteamer){
            video= this.$refs.localAudio;
            this.unsavedChanges();
            //location.href = "/";
          }else{
           video= this.$refs.remoteAudio;
         //  location.href = "/";
          }
          this.unsavedChanges();
          if (video.paused){
              video.play();
          }else{
            video.pause();
          }
          location.href = "/";

        },

        toggleAudio() {
          if(this.isSteamer){
            if(this.ismic){
              this.ismic=false;
              this.micOn=baseimg.inactiveMic;
              this.$refs.micOn.src=baseimg.inactiveMic;
            }else{
              this.ismic=true;
              this.micOn=baseimg.activeMic;
              this.$refs.micOn.src=baseimg.activeMic;
            }
            //this.local_stream.getAudioTracks()[0].enabled = !this.local_stream.getAudioTracks()[0].enabled;
            console.log(this.local_stream.getAudioTracks()[0]);
            let audio= this.$refs.localAudio.srcObject;
            if(audio.getAudioTracks()[0].enabled==true){
              audio.getAudioTracks()[0].enabled=false;
              audio.muted=true;
            }else{
              audio.getAudioTracks()[0].enabled=true;
              audio.muted=false;
            }

          }else{
            if(this.ismic){
              this.ismic=false;
              this.micOn=baseimg.inactiveMic;
              this.$refs.micOn.src=baseimg.inactiveMic;
            }else{
              this.ismic=true;
              this.micOn=baseimg.activeMic;
              this.$refs.micOn.src=baseimg.activeMic;
            }
            let audio= this.$refs.localAudio.srcObject;
            if(audio.getAudioTracks()[0].enabled==true){
              audio.getAudioTracks()[0].enabled=false;
            }else{
              audio.getAudioTracks()[0].enabled=true;
            }
            //this.peerStream.getAudioTracks()[0].enabled = !this.peerStream.getAudioTracks()[0].enabled;
          }
        },
        toggleCamera(){
          if(this.isSteamer){
            if(this.isCamera){
              this.isCamera=false;
              this.cameraon=baseimg.inactiveCamera;
              this.$refs.cameraOn.src=baseimg.inactiveCamera;
            }else{
              this.isCamera=true;
              this.cameraon=baseimg.activeCamera;
              this.$refs.cameraOn.src=baseimg.activeCamera;
            }
            let video= this.$refs.localAudio.srcObject;
            if(video.getVideoTracks()[0].enabled==true){
              video.getVideoTracks()[0].enabled=false;
            }else{
              video.getVideoTracks()[0].enabled=true;
            }
            //video.getVideoTracks()[0].enabled = !video.getVideoTracks()[0].enabled;
           // this.local_stream.getVideoTracks()[0].enabled = !this.local_stream.getVideoTracks()[0].enabled;
          }else{
            if(this.isCamera){
              this.isCamera=false;
              this.cameraon=baseimg.inactiveCamera;
            }else{
              this.isCamera=true;
              this.cameraon=baseimg.activeCamera;
            }

            //this.peerStream.getVideoTracks()[0].enabled = !this.peerStream.getVideoTracks()[0].enabled;
          }

        },
        toggleSwitchcamera(){
         // this.whichCamera(this.videoTrack);
          if(this.isSteamer){
            this.front = !this. front;
            //let isfront=0;
            /**if(localStorage.getItem("isfront") === null) {
                isfront=0
                this.front=true;
              }
              else if (localStorage.getItem("isfront")==0) {
                isfront=1
                this.front=false;
              }
              else{
               isfront=0; // localStorage.getItem("isfront");
               this.front=true;
              } **/
            if(this.front==true){
              this.isbackfront=false;
              this.$refs.backCameraOn.src=baseimg.backCamera;
              this.environment='user';
              localStorage.setItem("isfront", 0);
              this.switchCameras(this.videoTrack,this.environment);
            }else if(this.front==false){
              this.isbackfront=true;
              this.$refs.backCameraOn.src=baseimg.frontCamera;
              this.environment='environment';
              localStorage.setItem("isfront", 1);
              this.switchCameras(this.videoTrack,this.environment);
            }

          }else{
            if(this.isbackfront){
              this.isbackfront=false;
              this.$refs.backCameraOn.src=baseimg.backCamera;
            }else{
              this.isbackfront=true;
              this.$refs.backCameraOn.src=baseimg.frontCamera;
            }
          }
        },

        toogleValuelevel(){
          if(this.isSteamer){
            if(this.isvalueme){
              this.isvalueme=false;
              this.$refs.soundOn.src=baseimg.inactiveSound;
              let video= this.$refs.localAudio;
              video.muted = !video.muted

            }else{
              this.isvalueme=true;
              this.$refs.soundOn.src=baseimg.activeSound;
              let video= this.$refs.localAudio;
              video.muted = !video.muted
            }
          }else{
            if(this.isvalueme){
              this.isvalueme=false;
              this.$refs.soundOn.src=baseimg.inactiveSound;
              let video= this.$refs.remoteAudio;
              video.muted = !video.muted

            }else{
              this.isvalueme=true;
              this.$refs.soundOn.src=baseimg.activeSound;
              let video= this.$refs.remoteAudio;
              video.muted = !video.muted

            }
          }
        },
        togglePictureInPicture() {
          let video= null;
          if(this.isSteamer){
            if(this.ispinp){
              this.ispinp=false;
              this.$refs.picInpicOn.src=baseimg.activepicInpic;
            }else{
              this.ispinp=true;
              this.$refs.picInpicOn.src=baseimg.inactivepicInpic;
            }
            video= this.$refs.localAudio;
          }else{
            if(this.ispinp){
              this.ispinp=false;
              this.$refs.picInpicOn.src=baseimg.activepicInpic;
            }else{
              this.ispinp=true;
              this.$refs.picInpicOn.src=baseimg.inactivepicInpic;
            }
            video= this.$refs.remoteAudio;
          }
          if (document.pictureInPictureElement) {
               document.exitPictureInPicture();
          } else if (document.pictureInPictureEnabled) {
              video.requestPictureInPicture();
          }
        },
        getPermissions() {
            return new Promise((res, rej) => {
              var constraints = { video: { facingMode: (this.front? "user" : "environment") },audio:true };
              navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                        res(stream);
                    })
                    .catch(err => {
                        throw new Error(`Unable to fetch stream ${err}`);
                    });
            });
        },

        getPermissionsviewer(peer,room_id) {
            return new Promise((res, rej) => {
                navigator.mediaDevices
                    .getUserMedia({ video: true, audio: true })
                    .then(stream => {
                        this.localStorage = stream;
                        this.setLocalStream(stream);
                        let call = peer.call(room_id, stream);
                        call.on('stream', (stream) => {
                            this.setRemoteStream(stream);
                        })
                        this.currentPeer = call;
                        res(stream);
                    })
                    .catch(err => {
                        throw new Error(`Unable to fetch stream ${err}`);
                    });
            });
        },
        async logout() {
          const userauth = localStorage.getItem("userId");

            try {
                await api.user.updateToOffline(userauth);
            } finally {
                if (this.user.id) {
                    await api.auth.logout();
                }
                localStorage.removeItem("userId");
                localStorage.removeItem("isLoggedIn");
                location.href = "/login";
                this.$router.push({ name: "Login" });
            }
        },
        async NewWebrtc(constraints){
          navigator.mediaDevices
                      .getUserMedia(constraints)
                      .then((stream) => {
                       let  videoElement = '';
                       if(document.getElementById("myVideo") !="undefined"){
                       videoElement=document.getElementById("myVideo");
                       }else{
                        videoElement= this.$refs.localAudio
                       }

                        const audioTracks = stream.getAudioTracks();
                        const videoTracks = stream.getVideoTracks();
                        videoElement.srcObject = stream;
                        this.local_stream=stream;
                        if (audioTracks.length > 0) {
                          this.audioTrack = audioTracks[0];
                        }
                        if (videoTracks.length > 0) {
                          this.videoTrack = videoTracks[0];
                        }
                      })
                      .then(() => {
                        return new Promise((resolve) => {
                          videoElement.onloadedmetadata = resolve;
                        });
                      })
                      .then(() => {
                        this.getCurrentSettings();
                      })
                      .catch(this.handleError);
                    }
      }
    });
</script>






<style>
        * {box-sizing: border-box;
          padding: 0;
        margin: 0;
      }


    .div-container.app-header {
        background: #2e2e2e;
        height: auto;
        padding: 5px;
        box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
      }
      .div-col12 {
  width: 100%;
}
.app-body-container.appbody-content {
  padding: 15px 0;
}
.div-row {
  flex-wrap: wrap;
  display: flex;
}
      .body-cont {
  max-width: 700px;
  margin: 0 auto;
}
.logo-sec {
  width: 60px;
  float: left;
}
.app-logo {
  width: 100%;
}

ul{
  padding: 0;
  margin: 0;

}
.headlogo-li{
  list-style: none;
    float: left;
    width: 33.3%;
}
.video-icon-sec {
  width: 50px;
margin: 5px auto;
}
.profile-img-sec {
  width: 60px;
  border-radius: 100%;
  float: right;
  text-align: center;
  height: 60px;
  padding-top: 6px;
}
.net-profile-img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 50px;
  box-shadow: 0px 6px 12px 0px rgba(0,0,164,0.29);
  z-index: 1;
  top: 70px;
  right: 0;
}

.dropdown-content a {
  color: #3C4043;;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.dropdown-content a:hover {background-color: #ddd; color:#0000A4;}

.dropdown:hover .dropdown-content {display: block;}

.profile-menu i{
  font-size: 16px;
  margin-right: 12px;
}
.app-profl-sec{
    position: relative;
  background: #BBB6B6;
  border-radius: 20px;
  box-shadow: 0px 0px 14px -6px rgba(0,0,164,1);
}


.app-body-contli {
width: 44%;
list-style: none;
margin: 10px;
display: inline-block;
}
.profile-cont-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  min-height: 200px;
  border-radius: 20px;

}
.text-block {
  position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.9);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 1;
  color: #3C4043;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profname {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.app-body-ul{
  margin: 0 auto;
}
.profname span{
  width:33.3%;
  float: left;
  font-size: 16px;
  padding: 0;
margin: 0;
}
.profclock-span {
  text-align: center;
    padding: 0;
margin: 0;
}
.profstar-span {
  text-align: right;
  padding: 0;
margin: 0;
}
.profstar-span:hover {
  color: #E4AC1A;
}
/*.search-sec {

  border-radius: 100px;
  border: 1px solid #A6A6A6;

  padding: 5px;
}*/
.search-sec input{
  border: none;
  width: 75%;
  border-radius: 100px;
  padding: 10px 15px;
}
.app-bottom-col {
  display: flex;
}

.filt-view {
  text-align: right;
  text-align: center;
}
.thumbview-span {
  width: 50%;
display: inline-block;
text-align: center;
padding: 0 8px;
border-top-left-radius: 30px;
border-bottom-left-radius: 30px;
}
.mapview-span{
  width: 50%;
display: inline-block;
text-align: center;
padding: 12px 8px;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;


}
.filt-view {
  padding: 0;
  margin: 0;
border: 1px solid #ced4da;
border-radius: 30px;

}
.filt-view button{
  background: #fff;
  border: none;
}
.app-bottom-container.app-bottom {
  background: #fff;
  width: 100%;
height: auto;
padding: 10px;
display: flex;
position: fixed;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.appbody-profcont{
  width: 100%;
}
.appbody-profcont tr td{
  padding: 10px;
}
.prof-namcont tr td{
  padding: 10px 5px;
  width: 33%;
}
.prof-namcont{
  width: 100%;
}
.botbar-table{
  width: 100%;
}
.botbar-table tr td:nth-child(1){
  width: 65%;
}


.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-control.input-form {
  padding: 15px 15px;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
    margin-bottom: 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-icon {
  background: transparent;
  color: #888;
  font-size: 24px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.app-bottom-row {
  width: 100%;
}

table {
  border-collapse: collapse;
}
.form-control:focus-visible{
  border: none;
  outline: none;
  box-shadow: 0;
}

.search-sec .input-group {
  border: 1px solid #ced4da;
  border-radius: 50px;
}
.input-group-text.input-icon {
  border: none;
  background: transparent;
}



.mainprof-sec .input-group {
  border: 2px solid #ced4da;


}
.mainprof-input{
  margin-top:15px ;
}

.cover-photosec {
  display: block;
  width: 100%;
  height: 350px;
  border: 2px solid #ced4da;

  margin-top: 15px;

}
.mainprof-botbar-table {
  width: 100%;
}
.bot-livbtn {
width: 60px;
display: block;
border: none;
border-radius: 100%;
height: 60px;

margin: 0 auto;
text-align: center;
box-shadow: 0px 0px 16px -8px rgba(0,0,164,0.75);
color: #949497;
font-weight: 500;
}
.bot-livbtn .strambt-icon {
  width: 100%;
}
.mainprof-botbar-table tr td {
  width: 20%;
  text-align: center;
}
.bot-icon i{
  font-size: 18px;
  color: #949497;
}
.bot-icon {
  display: block;
  width: 45px;
  margin: 0 auto;
  height: 45px;
  border-radius: 100%;
  line-height: 30px;
  background: #DFDFDF;
}
.bot-icon img{
  width: 100%;
}
.bot-icon.active{
  background: #0000A4;

}
.bot-icon.active i{
  color: #fff !important;
}

.mainprof-sec .input-group {
  border: 2px solid #ced4da;
}
.form-control.input-form {
  border: none;
}
.mart15{
  margin-top: 15px;
}
.app-body-container.cratstrbody-content{
  padding: 15px;
}
.input-group.mainprof-input {
  border: 2px solid #ced4da;
}





.socialicon-listul{
  list-style: none;
}
.socialicon-list {
  max-width: 50px;
  position: absolute;
  right: 30px;
  bottom: 70px;
  display: none;
}
.socialicon-list li a {
  display: block;
  padding:10px;
  font-size: 18px;
  border-radius: 100%;
  background: #0000a4;
  color: #fff;
  margin: 5px 0;
}
.socialicon-list {
  max-width: 50px;
  position: absolute;
  right: 30px;
  bottom: 70px;
}


#share-button:focus + .socialicon-list{
  display:block !important;
}







.sidber-notification {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top:70px;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: -4px 0px 5px 0px rgba(0,0,164,0.29);
}

.sidber-notification a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: blue;
  display: block;
  transition: 0.3s;
}

.sidber-notification a:hover {
  color: #f1f1f1;
}

.sidber-notification .closebtn {
  font-size: 20px;
  background: #2e2e2e;
  box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
  color: #fff;
}
.sidber-notification .closebtn:hover {
  font-size: 20px;
  background: #2e2e2e;
}
.sidber-notification .closebtn span {
 font-size: 40px;
  float: right;
  line-height: 20px;

}

@media screen and (max-height: 450px) {
  .sidber-notification {padding-top: 15px;}
  .sidber-notification a {font-size: 18px;}
}



.mostrecnt_contac {
  height: 40%;
}

.contac_sec {
  height: 40%;
}
.contct_heding {
  font-size: 20px;
  padding: 8px 32px;
  background: #2e2e2e;
  color: #fff;
}






.app-body-container.livebody-content {
  height: 90vh;
}
.live-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  height: 100vh;
}
.livestream-body{
  height: 90vh;
  position: relative;
  z-index: 0;
}
.livestram-boticon {
  width: 100%;
  height: auto;
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,0.7775442940848214) 81%, rgba(255,255,255,0.5030344901632529) 100%);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

}

.vierslist-tavle {
  width: 100%;
}
.vierslist-tavle .total-viersnum {
  text-align: right;
  color: #fff;
}
.vierslist-tavle .viewrs-titletxt{
  font-size: 16px;
   color: #fff;
}
.vierslist-tavle .viewrs-titletxt span{
  font-size: 16px;
  margin-right: 10px;
   color: #fff;
}
.viewers-sec{
  padding: 10px;
  background: #000;
}
.liveacc-imgicon {
  padding: 10px;
}
.viersname{
  color: #fff;
  font-size: 14px;
  padding: 7px 0;
  border-bottom: 1px solid #303841;
}
.viewrs-list {
  padding: 16px 0 5px 0;
}

.viewrs-list{
  display: block;
}


.viewrs-numsec{
  cursor: pointer;
}




.live-bot-icon {
  display: block;
  width: 30px;
  margin: 0 auto;
  height: 30px;
  border-radius: 100%;
  line-height: 30px;
}
.liveacc-botbar-table{
  width: 100%;
}
.liveacc-botbar-table tr td {
  width: 13%;
  text-align: center;
}
.live-bot-icon img{
  width: 100%;
}
.live-bot-livbtn {
  border: none;
  background: transparent;
}
.viewer{
    width: 170px;
    float: right;
    position: relative;
    z-index: 1;
}
.other-user-videoess{
  width: 100vh;
  position: absolute;
  height: 100vh;
}
.div-col12 {
    width: 100%;
    z-index: 999999999999999999;
}
.streem {
    width: auto;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    height: 100vh;
    top: 73%;
    left: 63%;
    transform: translate(-51%,-68%);
}
.remoteviewer{
  width: auto;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    right: 0;
    height: 100vh;
    top: 73%;
    left: 63%;
    transform: translate(-51%,-68%);
}
video[poster]{ object-fit:none; }  /* or object-fit:fill */

</style>
